body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Typo */

html {
  background: #fff;
}

body {
  font-family: 'Inter', sans-serif;
  cursor: none;
  overflow-x: hidden;
}

.logo {
  height: 16px;
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
}

a {
  color: #000;
}

a:hover {
  cursor: none;
}

p {
  letter-spacing: -0.05rem;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.tiny-label {
  font-size: 16px;
  font-weight: 500;
}

h1, h2, h3, p {
  margin: 0;
}

h1, h2, h3 {
  font-weight: 600;
}

h2 {
  font-size: 48px;
  letter-spacing: -0.15rem;
  line-height: 1.1;
}


/* Navigation */

.navigation {
  position: fixed;
  top: 16px;
  left: 24px;
  right: 24px;
  display: flex;
  justify-content: space-between;
  z-index: 1001;
}

.links {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
}

.links a {
  margin-right: 16px;
  text-decoration: none;
  color: #000;
  font-size: 14px;
  letter-spacing: -0.05rem;
  }

  .links a:hover {
    text-decoration: underline;
  }

  .links a:last-child {
    margin-right: 0;
  }

  .nav-right-wrapper {
    max-width: 640px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .scroll-arrow {
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  .scroll-arrow.rotated {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

/* Pages */

.page-wrapper {
  position: relative;
  padding: 0 24px;
}

.fade-exit-active .page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fade-enter {
  opacity: 0;
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
}

.fade-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: opacity 500ms, -webkit-transform 800ms;
  transition: opacity 500ms, transform 800ms;
  transition: opacity 500ms, transform 800ms, -webkit-transform 800ms;
}

.fade-exit {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  -webkit-transform: translateY(-104px);
          transform: translateY(-104px);
  transition: opacity 500ms, -webkit-transform 800ms;
  transition: opacity 500ms, transform 800ms;
  transition: opacity 500ms, transform 800ms, -webkit-transform 800ms;
}


/* Hero */

.hero {
  height: calc(100vh - 128px);
  padding-top: 104px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

h1 {
  font-size: 56px;
  letter-spacing: -0.15rem;
  max-width: 800px;
  width: 100%;
  line-height: 1;
  font-weight: 600;
}

.wide-h1 {
  max-width: 900px;
}

.intro-wrapper {
  width: 100%;
  font-size: 28px;
  line-height: 1.15;
  align-self: flex-end;
  max-width: 640px;
}

.intro {
  max-width: 580px;
  letter-spacing: -0.075rem;
  margin-bottom: 48px;
}

.no-marg {
  margin-bottom: 0 !important;
}

.contact-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  max-width: 640px;
}

.contact-wrapper a {
  font-size: 16px;
  color: #000;
}

.first-links a:nth-child(1) {
  margin-right: 32px;
}

/* Testimonials */

.box {
  margin: 104px 0px;
  border-radius: 16px;
  padding: 104px 32px;
  display: flex;
  position: relative;
}

.yellow-box {
  background: #FFC700;
  color: #512004;
}

.black-box {
  background: #000;
  color: #fff;
  font-size: 19px;
  line-height: 1.3;
}

.box-inner-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.yellow-inner-wrapper .tiny-label {
  color: #512004;
  max-width: 200px;
}

.full-w {
  max-width: unset !important;
}

.max-w-h2 {
  max-width: 540px;
}

.box-inner-content {
  max-width: 608px;
  width: 100%;
  font-size: 19px;
  letter-spacing: -0.05rem;
}

.box-inner-content h2 {
  margin-bottom: 48px;
}

.yellow-inner-content h2 {
  color: #512004;
}

.yellow-inner-content p {
  color: #512004;
}

.yellow-inner-content ul {
  color: #512004;
  line-height: 1.5;
}

.quote-wrapper {
  margin-bottom: 64px;
  color: #512004;
}

.quote-wrapper:nth-child(4) {
  margin-bottom: 0;
}

.quote {
  text-indent: 72px;
  font-size: 19px;
  line-height: 1.3;
  margin-bottom: 32px;
}

.reference {
  display: flex;
  align-items: center;
}

.reference img {
  height: 56px;
  width: 56px;
  border-radius: 8px;
  margin-right: 16px;
}

.reference-p {
  font-size: 15px;
}


/* Module */

.module-wrapper {
  display: flex;
  justify-content: space-between;
}

.module-right {
  max-width: 640px;
  width: 100%;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 48px;
  font-size: 19px;
  line-height: 1.3;
}

.page-link {
  font-size: 19px;
  font-weight: 500;
  text-decoration: underline;
  margin-top: 32px;
  display: inline-block;
  letter-spacing: -0.05rem;
  display: flex;
  align-items: center;
}

.page-link svg {
  margin-right: 8px;
  display: block;
  position: relative;
  transition: ease-out 0.25s;
  top: 1px;
}

.page-link:hover svg {
  margin-left: 4px;
  transition: ease-out 0.25s;
}

.page-link:hover span {
  margin-left: -4px;
  transition: ease-out 0.25s;
}

.page-link span {
  transition: ease-out 0.25s;
}

/* Footer */

.footer {
  display: flex;
  justify-content: space-between;
  margin: 0px 24px 24px 24px;
  font-size: 15px;
  letter-spacing: -0.025rem;
}

.footer-links {
  max-width: 640px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-first-links a {
  margin-right: 32px;
}

.phone-link-v {
  text-align: right;
}


/* Someday logo hover */

.someday-logo {
  font-size: 25vw;
  letter-spacing: -2.25vw;
  display: flex;
  margin-left: -16px;
  font-weight: 500;
  margin-bottom: 64px;
}

.char {
  display: block;
  transition: 0.5s;
  line-height: 1;
}

.someday-logo:hover .char {
  -webkit-transform: rotate(6deg);
          transform: rotate(6deg);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: 0.5s;
}

.border-black {
  height: 1px;
  width: 100%;
  background: #000;
  margin-bottom: 24px;
}

.malthese {
  width: 22.901vw;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 16px;
}


/* Services */

.max-w-640 {
  max-width: 640px;
}

.intro-services {
  max-width: 640px;
  margin-bottom: 0 !important;
  line-height: 1.35;
}

.toggle {
  margin-bottom: 56px;
}

.toggle-header {
  border-top: 1px solid #512004;
}

.toggle-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.toggle-number {
  font-size: 13px;
  margin-top: 8px;
}

.toggle-number:before {
  content: "0";
}

.toggle-action {
  font-weight: 400;
  font-size: 21px;
}

.toggle-title {
  font-size: 18px;
}

.toggle-content {
  font-size: 15px;
  line-height: 1.35;
  margin-top: 12px;
}

.up-top {
  margin-top: 40px;
}

/* For who */

.scenario {
  color: #fff;
}

.scenario h2 {
  margin-bottom: 32px;
}

.white-line {
  height: 1px;
  width: 100%;
  margin: 72px 0;
  background: #fff;
}

/* Defaults */

.default-text {
  font-size: 19px;
  line-height: 1.35;
}

/* The Why */

.manifesto-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-top: 104px;
  margin-bottom: 120px;
}

.gif-wrapper {
  position: absolute;
  bottom: -4px;
  left: 0px;
}

.gif {
  max-width: 250px;
}

.timeline-item {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.15rem;
  margin-bottom: 48px;
  max-width: 640px;
}

.yellow {
  color: #FFC700;
}

/* Responsive */

@media (min-width: 1441px) {
  .timeline-item {
    font-size: 3.886vw;
  }

  .tiny-label {
    font-size: 1.110vw;
  }

  h2 {
    font-size: 3.331vw;
  }

  .wide-h1 {
    max-width: 74.784vw;
  }

  .links a {
    margin-right: 1.110vw;
    font-size: 0.972vw;
  }

  .hero {
    height: calc(100vh - 8.883vw);
    padding-top: 7.217vw;
  }

  h1 {
    font-size: 3.886vw;
    max-width: 55.517vw;
  }

  .intro-wrapper {
    font-size: 1.943vw;
    max-width: 44.414vw;
  }

  .intro {
    max-width: 40.250vw;
    margin-bottom: 3.331vw;
  }
  .contact-wrapper {
    max-width: 44.414vw;
  }

  .contact-wrapper a {
    font-size: 1.110vw;
  }

  .first-links a:nth-child(1) {
    margin-right: 2.221vw;
  }

  .box {
    margin: 7.217vw 0px;
    border-radius: 1.110vw;
    padding: 7.217vw 2.221vw;
  }

  .black-box {
    font-size: 1.319vw;
  }

  .yellow-inner-wrapper .tiny-label {
    max-width: 13.879vw;
  }

  .max-w-h2 {
    max-width: 37.474vw;
  }

  .box-inner-content {
    max-width: 42.193vw;
    font-size: 1.319vw;
  }

  .box-inner-content h2 {
    margin-bottom: 3.331vw;
  }

  .quote-wrapper {
    margin-bottom: 4.441vw;
  }

  .quote {
    text-indent: 4.997vw;
    font-size: 1.319vw;
    margin-bottom: 2.221vw;
  }

  .reference img {
    height: 3.886vw;
    width: 3.886vw;
    border-radius: 0.555vw;
    margin-right: 1.110vw;
  }
  .reference-p {
    font-size: 1.041vw;
  }
  .module-right {
    max-width: 44.414vw;
  }
  .two-columns {
    grid-gap: 1.110vw;
    margin-top: 3.331vw;
    font-size: 1.319vw;
  }
  .page-link {
    font-size: 1.319vw;
    margin-top: 2.221vw;
  }

  .page-link svg {
    margin-right: 0.555vw;
  }
  .footer {
    margin: 0px 1.666vw 1.666vw 1.666vw;
    font-size: 1.041vw;
  }

  .footer-links {
    max-width: 44.414vw;
  }

  .footer-first-links a {
    margin-right: 2.221vw;
  }

  .someday-logo {
    margin-left: -1.110vw;
    margin-bottom: 4.441vw;
  }
  .border-black {
    height: 0.069vw;
    margin-bottom: 1.666vw;
  }

  .malthese {
    width: 22.901vw;
    border-radius: 1.110vw;
  }

  .max-w-640 {
    max-width: 46.414vw;
  }

  .intro-services {
    max-width: 44.414vw;
  }

  .toggle {
    margin-bottom: 3.886vw;
  }

  .toggle-header {
    border-top: 0.069vw solid #512004;
  }
  .toggle-top {
    margin-bottom: 1.666vw;
  }
  .toggle-number {
    font-size: 0.902vw;
    margin-top: 0.555vw;
  }
  .toggle-action {
    font-size: 1.457vw;
  }
  .toggle-title {
    font-size: 1.249vw;
  }

  .toggle-content {
    font-size: 1.041vw;
    margin-top: 0.833vw;
  }
  .scenario h2 {
    margin-bottom: 2.221vw;
  }

  .white-line {
    height: 0.069vw;
    margin: 4.997vw 0;
  }
  .default-text {
    font-size: 1.319vw;
  }

  .timeline-item {
    font-size: 3.886vw;
  }

  .logo {
    height: 1.110vw;
  }

  .up-top {
    margin-top: 2.776vw;
  }

  .manifesto-wrapper {
    margin-top: 7.217vw;
    margin-bottom: 8.328vw;
  }

  .timeline-item {
    font-size: 2.776vw;
    margin-bottom: 3.331vw;
    max-width: 44.414vw;
  }
}

/* iPad Horizontal */

@media (max-width: 1024px) {
  .malthese {
    position: relative;
    margin-top: 48px;
    width: 310px;
  }

  .intro-wrapper {
    align-self: flex-start;
  }

  .hero {
    height: unset;
    margin-bottom: 104px;
  }
}


/* iPad Vertical */

@media (max-width: 768px) {
  .navigation {
    left: 16px;
    right: 16px;
  }

  .page-wrapper {
    padding: 0 16px;
  }

  .hero {
    height: 100%;
    padding-top: 128px;
  }

  .intro-wrapper {
    font-size: 21px;
  }

  .intro-wrapper {
    max-width: 100%;
    margin-top: 144px;
  }

  .intro {
    max-width: 100%;
  }

  .contact-wrapper {
    max-width: 100%;
  }

  .box-inner-wrapper {
    display: block;
  }

  .yellow-inner-wrapper .tiny-label {
    max-width: 100%;
  }

  h1 {
    font-size: 44px;
  }

  .box-inner-content {
    max-width: 100%;
    margin-top: 72px;
  }

  .box-inner-content h2 {
    margin-bottom: 32px;
  }

  .module-wrapper {
    display: block;
  }

  .module-right {
    max-width: 100%;
    margin-top: 48px;
  }

  .footer-links {
    max-width: 440px;
  }

  .custom-cursor {
    display: none;
  }

  a:hover {
    cursor: default;
  }

  body {
    cursor: default;
  }

  .manifesto {
    margin-top: 64px;
  }
}

/* iPhone */

@media (max-width: 414px) {
  h1 {
    font-size: 32px;
    line-height: 1.1;
    letter-spacing: -0.1rem;
  }

  .hero {
    margin-bottom: 80px;
  }

  .page-wrapper {
    padding: 0 12px;
}

  .intro-wrapper {
    font-size: 21px;
  }

  .intro-wrapper a {
    font-size: 14px;
  }

  .intro {
    letter-spacing: -0.035rem;
    line-height: 1.35;
  }

  .first-links a:nth-child(1) {
    margin-right: 16px;
  }

  .box {
    margin: 80px 0px;
    border-radius: 16px;
    padding: 64px 20px;
    display: flex;
  }

  .tiny-label {
    font-size: 14px;
  }

  .box-inner-content {
    margin-top: 48px;
    font-size: 16px;
    line-height: 1.35;
    letter-spacing: -0.035rem;
  }

  p {
    letter-spacing: -0.035rem;
    line-height: 1.35;
  }

  .box-inner-content h2 {
    margin-bottom: 24px;
  }

  h2 {
    font-size: 32px;
  }

  .quote {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .quote-wrapper {
    margin-bottom: 48px;
  }

  .two-columns {
    grid-template-columns: 2fr;
    font-size: 16px;
    margin-top: 32px;
  }

  .page-link {
    font-size: 16px;
  }

  .page-link svg {
    width: 16px;
  }

  .footer {
    margin: 0px 16px 16px 16px;
    display: block;
  }

  .footer-links {
    margin-top: 8px;
  }

  .hide {
    display: none;
  }

  .intro-wrapper {
    margin-top: 24px;
    font-size: 16px;
  }

  .default-text {
    font-size: 16px;
    line-height: 1.35;
  }

  .malthese {
    width: 100%;
    border-radius: 16px;
    margin-bottom: -64px;
  }

  .full-wrapper {
    max-width: 100%;
  }

  .timeline-item {
    font-size: 32px;
  }

  .toggle-top {
    margin-bottom: 8px;
  }

  .toggle {
    margin-bottom: 40px;
  }

  .someday-logo {
    font-size: 24vw;
    margin: 0 auto;
    margin-bottom: 32px;
  }

  .timeline-item {
    font-size: 28px;
  }

  .scroll-arrow {
    display: none;
  }

  .nav-right-wrapper {
    max-width: unset;
    width: auto;
    top: -4px;
    position: relative;
  }
}

